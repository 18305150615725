export const BASE_USER_DISPLAY_FRAG = /* GraphQL */ `
  fragment BaseUserDisplayFrag on base_user {
    id
    reference_id
    full_name
    number
    external_reference_id
    photo {
      id
      url
    }
  }
`

export const BASE_USER_FRAG = /* GraphQL */ `
  fragment BaseUserFrag on base_user {
    id
    codename: reference_id
    name: full_name
    number
    external_reference_id
    is_verified
    companies {
      id
      company {
        id
        name
        codename
      }
    }
    current_address {
      id
      raw_address
    }
  }
`

export const USERS = /* GraphQL */ `
  query Users($groups: [String!], $query: String) {
    base_user(
      where: {
        groups: { group: { name: { _in: $groups } } }
        _or: [{ full_name: { _ilike: $query } }, { number: { _ilike: $query } }]
      }
      order_by: { full_name: asc_nulls_last }
      limit: 15
    ) {
      ...BaseUserFrag
    }
  }
  ${BASE_USER_FRAG}
`

export const BASE_USER_SEARCH = /* GraphQL */ `
  query SearchBaseUsers($groups: [String!], $query: String) {
    base_user(
      where: {
        groups: { group: { name: { _in: $groups } } }
        _or: [
          { full_name: { _ilike: $query } }
          { number: { _ilike: $query } }
          { external_reference_id2: { _ilike: $query } }
        ]
      }
      order_by: { full_name: asc_nulls_last }
      limit: 15
    ) {
      ...BaseUserFrag
    }
  }
  ${BASE_USER_FRAG}
`
